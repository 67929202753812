import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import DataProvider from "./context/DataContext";
import { createTheme } from "./theme";
import "simplebar-react/dist/simplebar.min.css";
import { AbilityContext, getAbility } from "./context/Can";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const defaultTheme = createTheme();
window.gStore = store;
root.render(
  <Provider store={store}>
    <ThemeProvider theme={defaultTheme}>
      {/* <CssBaseline /> */}
      <AbilityContext.Provider value={getAbility()}>
        <DataProvider>
          <Router future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Router>
        </DataProvider>
      </AbilityContext.Provider>
    </ThemeProvider>
  </Provider>
);
