import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import makeRequest from '../../makeRequest';
import { transformArray, getStatusBgColor } from '../../helpers';

export const fetchGlobalData = createAsyncThunk('data/fetchGlobalData', async (_, { getState }) => {
    const state = getState();
    const user = state.auth.user;
    if (!user) return {};

    const [
        productResp,
        postTypeResp,
        postStatusResp,
        channelTypeResp,
        channelListResp
    ] = await Promise.all([
        makeRequest({ method: 'GET', url: '/api/products?populate=*&publicationState=preview&sort[0]=id:asc' }),
        makeRequest({ method: 'GET', url: '/api/post/post-type-list' }),
        makeRequest({ method: 'GET', url: '/api/post/status-list' }),
        makeRequest({ method: 'GET', url: '/api/channels/channel-type-list' }),
        makeRequest({ method: 'GET', url: '/api/channels?populate=*&publicationState=preview&fields[0]=id&fields[1]=channel_id&fields[2]=channel_type&fields[3]=display_name' }),
    ]);

    return {
        products: productResp?.data || [],
        postTypes: transformArray(postTypeResp?.postTypeList, '-'),
        postStatusList: transformArray(postStatusResp?.statusList),
        medias: transformArray(channelTypeResp?.channelTypes, '_'),
        channels: channelListResp?.data || [],
    };
});

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        products: [],
        postTypes: [],
        postStatusList: [],
        medias: [],
        channels: [],
        selectedChannelType: null,
        loading: false,
        error: null,
    },
    reducers: {
        setSelectedChannelType: (state, action) => {
            state.selectedChannelType = action.payload;
        },
        setDataState(state, action) {
            const { key, value } = action.payload;
            state[key] = value;
        },
        clearDataState: (state) => {
            state.products = [];
            state.postTypes = [];
            state.postStatusList = [];
            state.medias = [];
            state.channels = [];
            state.selectedChannelType = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGlobalData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGlobalData.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.products;
                state.postTypes = action.payload.postTypes;
                state.postStatusList = action.payload.postStatusList.map((sItem) => ({
                    ...sItem,
                    backgroundColor: getStatusBgColor(sItem.value),
                }));
                state.medias = action.payload.medias;
                state.channels = action.payload.channels;
            })
            .addCase(fetchGlobalData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { setSelectedChannelType, setDataState, clearDataState } = dataSlice.actions;
export default dataSlice.reducer;