import React, { useState, useEffect } from 'react';
import makeRequest from '../../makeRequest';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Snackbar,
    Alert,
    Typography,
    IconButton,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Slider from 'react-slick';
import { useDataContext } from '../../context/DataContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { Can } from '../../context/Can';
import { subject } from "@casl/ability";
import { parseNumber } from '../../helpers';
import ClearIcon from '@mui/icons-material/Clear';
import { clearMessages, setGlobalMessage, setSuccessMessage } from '../../Redux/slices/uiSlice';
import SnackbarMessage from '../../components/SnackbarMessage';
import { useDispatch, useSelector } from "react-redux";


const ManualPostCreation = ({ currentProductId, handleClose }) => {
    const [channel, setChannel] = useState('');
    const [product, setProduct] = useState('');
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [content, setContent] = useState('');
    const [scheduledTime, setScheduledTime] = useState(null);
    const [mediaData, setMediaData] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const { products, channels, medias, postTypes, loading, postStatusList, filteredPostTypes, setSelectedChannelType } = useDataContext();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const authState = useSelector((state) => state.auth);
    const user = authState.user;
    const [updatedMedia, setUpdatedMedia] = useState([]);
    const [users, setUsers] = useState(false);
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const [socialMedia, setSocialMedia] = useState('');
    const [channelType, setChannelType] = useState('');
    const filteredChannels = channels?.filter(chan => chan.attributes.product?.data?.id === product);
    const [saving, setSaving] = useState(false);
    const userProducts = authState.user.userRoles?.map(item=>item.product) || [];
    const filteredProducts =  products.filter(fItem => userProducts.find(pItem=> fItem.id == pItem.id));
    const [frames, setFrames] = useState([]);
    const [selectedFrame, setSelectedFrame] = useState("");
    const [frameMedia, setFrameMedia] = useState(null);
    const dispatch = useDispatch();
    const uiState = useSelector((state) => state.ui);
    const { successMessage, errorMessage } = uiState;
    const [postContent, setPostContent] = useState("");
    const [startDate, setStartDate] = useState('');

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    zIndex: 1,
                }}
                onClick={onClick}
            >
                ➡️
            </div>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    zIndex: 1,
                }}
                onClick={onClick}
            >
                ⬅️
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // rows: 1,
        arrows: !(type === 'single-image-post' || type === 'single-video-post'),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        className: 'media-slider'
    };

    useEffect(() => {
        if (user) {
            setUsers(user);
        }
    }, [user]);

    const validateFields = () => {
        const errors = {};
        if (!product) errors.product = "Product is required";
        if (!channelType) errors.channelType = "Select social media";
        if (!channel) errors.channel = "Channel is required";
        if (!startDate) errors.startDate = "Scheduled time is required";
        if (!status) errors.status = "Select Status";
        if (!content.trim()) errors.content = "Content is required";
        if (!type) errors.type = "Select Type";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleCreatePost = async () => {
        if (!validateFields()) {
            return;
        }
        if (!user) {
            console.error("User not logged in");
            return;
        }
        let mediaToUpload = [];
        if (['single-image-post', 'multi-images', 'story-image'].includes(type)) {
            const invalidMedia = newMedia.filter(
                (media) => media.file && media.file.type.startsWith('video/')
            );

            if (invalidMedia.length > 0) {
                setIsLoading(false);
                dispatch(setGlobalMessage({ message: "Video files are not allowed. Please upload only images!", severity: "error" }));
                // alert("Video files are not allowed. Please upload only images!");
                return;
            }
        } else if (['single-video-post', 'multi-videos', 'story-video'].includes(type)) {
            const invalidMedia = newMedia.filter(
                (media) => media.file && !media.file.type.startsWith('video/')
            );

            if (invalidMedia.length > 0) {
                setIsLoading(false);
                dispatch(setGlobalMessage({ message: "Only video files are allowed. Please upload valid videos!", severity: "error" }));
                // alert("Only video files are allowed. Please upload valid videos!");
                return;
            }
        }
        // -------- Handle Media Upload --------
        if (newMedia.length > 0) {
            const formData = new FormData();
            for (const mediaItem of newMedia) {
                formData.append('files', mediaItem?.file);
            }
            setSaving(true);
            try {
                const uploadResponse = await makeRequest({
                    method: "POST",
                    url: "/api/upload",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (!uploadResponse) {
                    throw new Error("Failed to upload media.");
                }

                mediaToUpload = [...uploadResponse];
            } catch (error) {
                console.error("Media upload failed:", error);
                return;
            }
        }
        const dataToCreate = {
            product,
            channel: { connect: [{ id: channel }] },
            channelType,
            tm_scheduled: startDate ? startDate : null,
            content: content,
            type: type,
            status: status,
            scheduled_by: { connect: [{ id: user.id }] },
            media: mediaToUpload,
            jsonData: { selectedFrame, frameMedia }
        };
        setSaving(true);
        try {
            const response = await makeRequest({
                method: 'POST',
                url: '/api/posts/create-manual',
                data: dataToCreate,
            });
            setType('');
            setContent('');
            setStartDate(null);
            setStatus('');
            setChannel('');
            setProduct('');
            setChannelType('');
            setUpdatedMedia([]);
            setNewMedia([]);
            setValidationErrors({});
            setOpenSnackbar(true);
            setSaving(false);
            // dispatch(setSuccessMessage("Post created successfully!"));
            dispatch(setGlobalMessage({message:"Post created successfully!",severity:"success"}));
            navigate('/schedule?view=calendar');
        } catch (error) {
            dispatch(setGlobalMessage({message:"Something went wrong ",severity:"error"}));
            // console.error('Error saving post:', error);
        }
    };

    const handleAddMedia = (event) => {
        const files = Array.from(event.target.files).filter(file => {
            const isImage = file.type.startsWith('image/');
            const isVideo = file.type === 'video/mp4';
            if (type === 'single-video-post' || type === 'multi-video-post') {
                if (!isVideo) {
                    dispatch(setGlobalMessage({ message: "Only MP4 videos are allowed.", severity: "error" }));
                    // alert('Only MP4 videos are allowed.');
                    return false;
                }
            } else if (type === 'single-image-post' || type === 'multi-posts') {
                if (!isImage) {
                    dispatch(setGlobalMessage({ message: "Only images are allowed.", severity: "error" }));
                    // alert('Only images are allowed.');
                    return false;
                }
            }
            return true;
        });

        if (files.length === 0) {
            return;
        }

        const formattedFiles = files.map(file => ({
            file,
            img: URL.createObjectURL(file),
        }));
        setNewMedia(prevMedia => [...prevMedia, ...formattedFiles]);
    };

    const handleDeleteMedia = (item, index) => {
        setNewMedia(prev => prev.filter(fItem => fItem.img !== item.img));
        setMediaData(prev => prev.filter((_, i) => i !== index));
    };
    useEffect(() => {
        if (product && channelType) {
            const matchingChannel = filteredChannels.find(chan => chan.attributes.channel_type === channelType);
            if (matchingChannel) {
                setChannel(matchingChannel.id);
            } else {
                setChannel('');
            }
        }
    }, [product, channelType, filteredChannels]);

    const channelOptions = channels.map(channelItem => ({
        id: channelItem.id,
        label: `${channelItem.attributes.display_name} - ${channelItem.attributes.channel_type}`,
    }));

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        // dispatch(clearMessages());
    };

    const handleChannelTypeChange = (e) => {
        const selectedChannelType = e.target.value;
        setChannelType(selectedChannelType);
        setSelectedChannelType(selectedChannelType);
        setType('');
    };
    const formatPostType = (postType) => {
        return postType
            .replace(/-/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase());
    };
    
    const handleProductSelection = (e) =>{
        setProduct(e.target.value);
        const prodData = filteredProducts.find(pItem => pItem.id === e.target.value);
        console.log('MAN prodData: ', prodData);
        const fetchedFrames = prodData?.attributes?.frames || [];
        setFrames(fetchedFrames);
    }

    const handleFrameChange = (e) => {
        const frameId = e.target.value;
        setSelectedFrame(frameId);
        const frame = frames.find(f => f.id === frameId);
        console.log('MAN frame: ', frame);
        setFrameMedia(frame?.media?.data?.attributes?.url || "");
    };

    const handleClearFrame = () => {
        setSelectedFrame("");
        setFrameMedia(null);
    };

    return (
        <Box component="main">
            <Box>
                {isLoading && <Loader />}
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            label="Select Product"
                            fullWidth
                            value={product}
                            onChange={handleProductSelection}
                            required
                        >
                            {filteredProducts.map((pItem) => (
                                <MenuItem key={pItem.id} value={pItem.id}>
                                    {pItem.attributes.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.product && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.product}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            label="Select Channel Type"
                            fullWidth
                            value={channelType}
                            onChange={handleChannelTypeChange}
                            required
                            disabled={!product}
                        >
                            {medias.map((mItem) => (
                                <MenuItem key={mItem.value} value={mItem.value}>
                                    {mItem.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.channelType && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.channelType}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            value={channel}
                            label="Channel"
                            onChange={e => setChannel(e.target.value)}
                            required
                            disabled={!product || !channelType}
                        >
                            {filteredChannels?.map((chan) => (
                                <MenuItem key={chan.id} value={chan.id}>
                                    {chan.attributes.display_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.channel && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.channel}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            value={type}
                            label="Post Type"
                            fullWidth
                            required
                            onChange={e => setType(e.target.value)}
                            disabled={!channelType}
                        >
                            {filteredPostTypes.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {formatPostType(option)}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.type && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.type}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {/* <DateTimePicker
                                label="Scheduled Time *"
                                value={scheduledTime}
                                onChange={setScheduledTime}
                                required
                                sx={{
                                    width: '100%',
                                }}
                                slotProps={{
                                    popper: {
                                        sx: {
                                            '& .MuiPaper-root': {
                                                width: '500px', // Adjust the popup modal width
                                                // maxWidth: '500px', // Optional: Set a max width
                                                // padding: '16px', // Optional: Adjust padding inside the modal
                                                paddingBottom: 10
                                            },
                                        },
                                    },
                                }}
                            /> */}
                            <TextField
                            label="Start Date"
                            type="datetime-local"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                        />
                            {validationErrors.startDate && (
                                <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.startDate}</span>
                            )}
                        </LocalizationProvider>
                        
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            value={status}
                            label="Status"
                            fullWidth
                            required
                            onChange={e => setStatus(e.target.value)}
                        >
                            {postStatusList
                                .filter(option => option.value !== "published")
                                .map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </TextField>
                        {validationErrors.status && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.status}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Content"
                            multiline
                            rows={5}
                            value={content}
                            required
                            onChange={e => setContent(e.target.value)}
                            fullWidth
                        />
                        {validationErrors.content && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.content}</span>
                        )}
                    </Grid>

                    {type !== 'text' && (<Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <Grid justifyContent={'center'} >
                                <FormControl fullWidth>
                                    <InputLabel id="type-select-label">Media</InputLabel>
                                    <Box
                                        alignItems="center"
                                        sx={{
                                            // width: '460px',
                                            border: '1px solid lightgray',
                                            borderRadius: '4px',
                                        }}>
                                        {type === "text" ? (
                                            <Typography variant="subtitle1" color="textSecondary">
                                            </Typography>
                                        ) : mediaData.length === 0 && newMedia.length === 0 ? (
                                            <Box
                                                sx={{
                                                    height: '150px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '4px',
                                                    '&:hover .hover-actions': {
                                                        opacity: 0.9,
                                                    },
                                                }}
                                            >
                                                <Box
                                                    className="hover-actions"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{
                                                        opacity: 1,
                                                        transition: 'opacity 0.7s ease-in-out',
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    {/* ---------Add Media--------- */}
                                                    {!(type === 'single-image-post' || type === 'single-video-post') || (mediaData.length + newMedia.length) === 0 ? (
                                                        <IconButton
                                                            onClick={() => document.querySelector('#add-input-default').click()}
                                                            sx={{
                                                                color: 'black',
                                                                backgroundColor: 'gray',
                                                                '&:hover': { backgroundColor: 'white' },
                                                            }}
                                                        >
                                                            <AddSharpIcon />
                                                        </IconButton>
                                                    ) : null}
                                                    <input
                                                        type="file"
                                                        id="add-input-default"
                                                        hidden
                                                        // multiple
                                                        multiple={type !== 'single-video-post'}
                                                        accept={type === 'multi video post' || type === 'single-video-post' ? 'video/mp4' : '*/*'}
                                                        onChange={handleAddMedia}
                                                    />
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Slider {...settings}>
                                                {[...mediaData, ...newMedia].map((item, index) => (
                                                    <Box
                                                        key={index}
                                                        position="relative"
                                                        sx={{
                                                            width: '100%',
                                                            height: '150px',
                                                            overflow: 'hidden',
                                                            position: 'relative',
                                                            cursor: 'pointer',
                                                            backgroundColor: 'transparent',
                                                            '&:hover .hover-actions': {
                                                                opacity: 0.9,
                                                            },
                                                        }}
                                                    >
                                                        {item.file?.type.startsWith('video/') || item.img?.endsWith('.mp4') ? (
                                                            <video
                                                                controls
                                                                style={{
                                                                    width: '150px',
                                                                    height: '150px',
                                                                    margin: "0 auto",
                                                                    // display: 'flex',
                                                                    objectFit: 'cover',
                                                                    display: 'block',
                                                                    backgroundColor: '#fff',
                                                                }}
                                                            >
                                                                <source src={item.img} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            <Box component="div" position="relative">
                                                                <img
                                                                    src={item.img}
                                                                    alt={`media-${index}`}
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        margin: "0 auto",
                                                                        objectFit: 'cover',
                                                                        borderRadius: '8px',
                                                                        display: 'block',
                                                                        backgroundColor: '#fff',
                                                                    }}
                                                                />
                                                                {selectedFrame && <img alt="frame-img" src={frameMedia} style={{ position: 'absolute', left: 0, top: 0, width: '100%' }} />}
                                                            </Box>
                                                        )}

                                                        {/* -------Hover Actions------- */}
                                                        <Box
                                                            className="hover-actions"
                                                            position="absolute"
                                                            // top={0}
                                                            left={0}
                                                            right={0}
                                                            bottom={0}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            bgcolor="rgba(0, 0, 0, 0.8)"
                                                            color="#fff"
                                                            sx={{
                                                                opacity: 0,
                                                                transition: 'opacity 0.7s ease-in-out',
                                                                borderRadius: '4px'
                                                            }}
                                                        >
                                                            {/* -------Add Media ------ */}
                                                            {(!(type === 'single-image-post' || type === 'single-video-post') || (mediaData.length + newMedia.length) === 0) && (
                                                                <IconButton
                                                                    onClick={() => document.querySelector(`#add-input-${index}`).click()}
                                                                    sx={{
                                                                        marginRight: '8px',
                                                                        color: 'black',
                                                                        backgroundColor: 'gray',
                                                                        '&:hover': { backgroundColor: 'white' },
                                                                    }}
                                                                    disabled={type === 'single-image-post' || type === 'single-video-post' ? (mediaData.length + newMedia.length) === 1 : false}
                                                                >
                                                                    <AddSharpIcon />
                                                                </IconButton>
                                                            )}
                                                            {/* Delete Button */}
                                                            <IconButton
                                                                onClick={() => {
                                                                    handleDeleteMedia(item, index, index >= mediaData.length);
                                                                }}
                                                                sx={{
                                                                    color: "black",
                                                                    backgroundColor: 'gray',
                                                                    '&:hover': { backgroundColor: 'red' },
                                                                }}
                                                                disabled={type === "text"}
                                                            >
                                                                <DeleteOutlineOutlinedIcon />
                                                            </IconButton>

                                                            {/* Hidden File Input */}
                                                            <input
                                                                type="file"
                                                                id={`add-input-${index}`}
                                                                hidden
                                                                multiple
                                                                onChange={handleAddMedia}
                                                                disabled={type === "text"}
                                                            />
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Slider>
                                        )}
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Box>

                        {type === "single-image-post" && <FormControl fullWidth variant="outlined">
                                    <TextField
                                        select
                                        fullWidth
                                        label="Frames"
                                        value={selectedFrame}
                                        onChange={handleFrameChange}
                                        InputProps={{
                                            endAdornment: selectedFrame && (
                                                <IconButton onClick={handleClearFrame} sx={{mr:3}}>
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    >
                                        {frames.map((frame) => (
                                            <MenuItem key={frame.id} value={frame.id}>{frame.template}</MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>}
                    </Grid>)}

                    {/* )} */}
                        <Can
                            I='manage'
                            this={subject('ManagePosts', { productId: parseNumber(currentProductId) })}
                            field='productId'
                        >
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Button variant="contained" color="primary" onClick={handleCreatePost} disabled={saving}>
                                    {saving ? 'Saving...' : 'Save Post'}
                                </Button>
                            </Box>
                        </Grid>
                    </Can>
                </Grid>
            </Box>

            {/* <SnackbarMessage
        open={!!successMessage || !!errorMessage}
        message={successMessage || errorMessage}
        onClose={handleCloseSnackbar}
      /> */}
            {/* <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Post Created Successfully!
                </Alert>
            </Snackbar> */}
        </Box>
    );
};
export default ManualPostCreation;
