import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { logoutUser } from '../Redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import { setLoading } from '../Redux/slices/uiSlice';

function NotConfigured() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(setLoading(true));
    dispatch(logoutUser());
    dispatch(setLoading(false));
    navigate("/login", { replace: true });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
        px: 1,
      }}
    >
      <Container maxWidth="xl">
        <Box component="div" textAlign="center" py={6}>
          <Typography variant="h6" gutterBottom>
            You currently have no configured products.
          </Typography>
          <Typography variant="body1">
            Please contact <a href="mailto:mail@ceegees.in">mail@ceegees.in</a> to add your products.
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleLogout}
          >
            Logout
          </Button> */}
        </Box>
      </Container>
    </Box>
  );
}

export default NotConfigured;
