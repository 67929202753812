import React, { useState, useEffect, forwardRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme, Chip } from '@mui/material';
import makeRequest from '../makeRequest';

const MUIAutocomplete = (props) => {
  const { apiType, apiRequest, onSelect, label, selectedValue = null, fieldKey, disabled = false, error = false, helperText = '', required = false,
  multiple = false, placeholder = '', limitTags = 1, selectedOptions = [], fixedOptions = [], autoHighlight = false } = props;
    
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const fetchData = async (searchTerm, nextPage) => {
    setLoading(true);

    try {
      const resp = await makeRequest({
        ...apiRequest,
        // url: `${apiRequest.url}&page=${nextPage}&search=${searchTerm}`,
      });

      const { data, meta:{ pagination } } = resp;
      const newOptions = data.map((item) => {
        return ({
          id: item.id,
          label: apiType === 'REST' ?`${item.attributes[fieldKey]} - ${item.attributes.channel_type}`: item[fieldKey],
          data: item
        })
      });

      const newData = newOptions.filter(
        (newItem) => !options.some((existingItem) => existingItem.id === newItem.id)
      );
      setOptions((prevOptions) => [...prevOptions, ...newData]);
      setTotalPages(pagination?.pageCount);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleOpen = () => { setOpen(true); };

  const handleClose = () => { setOpen(false); };

  const handleScroll = (evt) => {
    const { scrollTop, clientHeight, scrollHeight } = evt.target;
    if (
      !loading &&
      scrollHeight - scrollTop === clientHeight &&
      page < totalPages
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSelect = (event, newValue) => {
    let updatedOptions = newValue;
    if (multiple) {
      updatedOptions = [
        ...fixedOptions,
        ...newValue.filter(option => !fixedOptions.some(fixedOption => fixedOption.id === option.id))
      ];
    }
    onSelect(updatedOptions);
  };


  useEffect(() => {
    if (open) {
      fetchData(inputValue, page);
    }
  }, [open, inputValue, page]);

  const filteredOptions = options.filter(option => !selectedOptions.includes(option.label));
  return (
    <Autocomplete
      autoHighlight={autoHighlight}
      multiple={multiple}
      disabled={disabled}
      value={selectedValue || (multiple ? [] : null)}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onInputChange={handleInputChange}
      onChange={handleSelect}
      options={filteredOptions}
      loading={loading}
      isOptionEqualToValue={(option, value) =>
        option?.label === value?.label
      }
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
      ListboxComponent={forwardRef((props, ref) => (
        <List {...props} ref={ref} onScroll={handleScroll} sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: '4px' }}>
          {props.children}
        </List>
      ))}
      renderOption={(props, option) => (
        option &&
        <ListItem key={option.id} {...props}>
          <ListItemText primary={option?.label} />
        </ListItem>
      )}
      filterSelectedOptions
      limitTags={limitTags}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={`au-chip-${option.id}`}
            label={option.label}
            {...getTagProps({ index })}
            disabled={fixedOptions.some(fItem => fItem.id === option.id && fItem.label === option.label)}
          />
        ))
      }
    />
  );
};

export default MUIAutocomplete;
