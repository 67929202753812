import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability'
import { userRoles } from '../constant';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export function updateAbility(roles, ability) {
    console.log('User Roles', roles);
    const { can, rules } = new AbilityBuilder();
    let viewSubjects = [];
    let manageSubjects = [];
    let attr = {};
    if (roles.length > 0) {
        roles.forEach(item => {
            console.log('Product id ---> ', item.product.id + '  ' +  'Product name ---> ', item.product.name + '  ' + ' Role ---> ', item.role);
            if (item.role === userRoles.OWNER) {
                viewSubjects = ['Products', 'Users', 'Dashboard', 'Posts', 'SchedulePost', 'Settings']
                manageSubjects = ['ConnectFacebook', 'ManageProducts', 'ManageUsers', 'ManagePosts']
                attr = {
                    productId: item?.product?.id
                };

            } else if (item.role === userRoles.MANAGER) {
                viewSubjects = ['Products', 'Users', 'Dashboard', 'Posts', 'SchedulePost', 'Settings']
                manageSubjects = ['ManagePosts']
                attr = {
                    productId: item.product.id
                };

            } else if (item.role === userRoles.ANALYST) {
                viewSubjects = ['Products', 'Users', 'Dashboard', 'Posts', 'SchedulePost', 'Settings']
                manageSubjects = []
                attr = {
                    productId: item.product.id
                };
            }
            can('view', viewSubjects, attr);
            can('manage', manageSubjects, attr);
        });
    } else if (roles.length === 0) {
        viewSubjects = ['Products', 'Users', 'Dashboard', 'Posts', 'SchedulePost', 'Settings']
        manageSubjects = ['ConnectFacebook', 'ManageProducts', 'ManageUsers', 'ManagePosts']
        attr = {}
    }
    can('view', viewSubjects, attr);
    can('manage', manageSubjects, attr);
    console.log('rules: ', rules);
    ability.update(rules);
}

export const getAbility = () => {
    return new Ability([]);
}