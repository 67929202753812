import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Paper } from '@mui/material';
import { Can } from '../../context/Can';

const ChannelList = ({ channels, handleFacebookConnect }) => {

  return (
    <Box sx={{ marginTop: 4 }}>
      <Can do="manage" on='ConnectFacebook'>
        <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={() => { handleFacebookConnect() }}>
            Connect Facebook
          </Button>
        </Box>
      </Can>

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        {channels.length > 0 ? (<Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'><Typography variant="body1" fontWeight="bold">Channel Name</Typography></TableCell>
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Channel Type</Typography></TableCell>
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Linked Product</Typography></TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {channels.map((channel, index) => (
              <TableRow key={channel?.attributes.channel_id}>
                <TableCell align='left'>{channel?.attributes.display_name}</TableCell>
                <TableCell align='center'>{channel?.attributes.channel_type || '-'}</TableCell>
                <TableCell align='center'>{channel?.attributes?.product.data?.attributes?.name || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>) : <Typography variant="body1" fontWeight="bold" sx={{ py: 4, textAlign: 'center' }}>Channels are unavailable. Please connect to Facebook to fetch channels and continue.</Typography>}
      </TableContainer>
    </Box>
  );
};

export default ChannelList;
