import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Tabs as MuiTabs, Tab, Container } from '@mui/material';
import PageHeader from './PageHeader';
import { removeNonValueFilters } from '../helpers';

const TabPanel = React.memo(({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    style={{ display: value === index ? 'block' : 'none' }}
  >
    <Box sx={{ p: 0.5, overflow: 'hidden' }}>{children}</Box>
  </div>
));

const Tabs = ({
  tabs,
  defaultView = '',
  pageTitle,
  pageIcon,
  showPageHeader = false,
  pageHeadRight,
}) => {
  const [queryParams, setQueryParams] = useSearchParams();
  const existingParams = Object.fromEntries([...queryParams]);
  const initialView = existingParams?.view || defaultView;
  const initialIndex = tabs.findIndex((tab) => tab.key === initialView);
  const [value, setValue] = useState(initialIndex >= 0 ? initialIndex : 0);

  const updateQueryParams = useCallback(
    (params) => {
      const updatedParams = removeNonValueFilters({ ...existingParams, ...params });
      setQueryParams(updatedParams);
    },
    [existingParams, setQueryParams]
  );

  const handleTabChange = (_, newValue) => {
    setValue(newValue);
    updateQueryParams({ view: tabs[newValue].key });
  };

  useEffect(() => {
    if (!existingParams?.view) return;
    const currentIndex = tabs.findIndex((tab) => tab.key === existingParams.view);
    if (currentIndex >= 0 && currentIndex !== value) {
      setValue(currentIndex);
    }
  }, [existingParams?.view, tabs, value]);

  useEffect(() => {
    if (!existingParams?.view && defaultView) {
      updateQueryParams({ view: defaultView });
    }
  }, [existingParams?.view, defaultView, updateQueryParams]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
        px: 1,
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="xl">
        {showPageHeader && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 2,
              flexWrap: 'wrap',
            }}
          >
            <PageHeader title={pageTitle} icon={pageIcon} rightContent={pageHeadRight} />
          </Box>
        )}
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <MuiTabs
            value={value}
            onChange={handleTabChange}
            sx={{ mx: 1.5 }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.key}
                label={tab.label}
                value={index}
                disabled={tab.disabled}
              />
            ))}
          </MuiTabs>
          {tabs.map((tab, index) => (
            <TabPanel key={tab.key} value={value} index={index}>
              {tab.content}
            </TabPanel>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Tabs;
