import { createContext, useContext, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchGlobalData, setSelectedChannelType } from '../Redux/slices/dataSlice';

const DataContext = createContext({});

export const useDataContext = () => useContext(DataContext);

const postTypeMapping = {
    facebook_page: [
        "text",
        "single-image-post",
        "single-video-post",
        "reels",
        "multi-images",
        "story-image",
        "story-video"
    ],
    instagram_page: [
        "single-image-post",
        "reels",
        "multi-images",
        "story-image",
        "story-video"
    ],
};

const DataProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { products, medias, channels, postTypes, postStatusList, loading, error, selectedChannelType } = useSelector((state) => state.data);
    const authState = useSelector((state) => state.auth);

    useEffect(() => {
        if (authState.user) {
            dispatch(fetchGlobalData());
        }
    }, [authState.user, dispatch]);

    const filteredPostTypes = useMemo(() => {
        return selectedChannelType
            ? postTypeMapping[selectedChannelType] || []
            : [];
    }, [selectedChannelType, postTypeMapping]);

    return (
        <DataContext.Provider
            value={{
                products,
                medias,
                channels,
                postTypes,
                postStatusList,
                loading,
                error,
                selectedChannelType,
                setSelectedChannelType: (type) => dispatch(setSelectedChannelType(type)),
                filteredPostTypes,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataProvider;