import { Container, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Grid from '@mui/material/Unstable_Grid2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import Insights from './Insights';
import ProductChannelStats from './ProductChannelStats';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProductPostStats from './ProductPostStats';
import { CountCard } from './CountCard';
import GroupedCard from './GroupedCard';
import LikesCommentChart from './LikesCommentChart';
import makeRequest from '../../../src/makeRequest';
import { useSelector } from 'react-redux';

function Dashboard({ productId }) {
    const [totalPosts, setTotalPosts] = useState(0);
    const [totalComments, setTotalComments] = useState(0);
    const [totalLikes, setTotalLikes] = useState(0);
    const [productStats, setProductStats] = useState([]);
    const [dynamicCardLoading, setDynamicCardLoading] = useState(true);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [loadingComments, setLoadingComments] = useState(true);
    const [loadingLikes, setLoadingLikes] = useState(true);
    const [groupedCardLoading, setGroupedCardLoading] = useState(true);
    const [groupedCardData, setGroupedCardData] = useState([]);
    const [productCard, setProductCard] = useState([]);
    const [productFollowerCount, setProductFollowerCount] = useState([]);
    // const dataState = useSelector(state=>state.data);

    const fetchAllPostCounts = async () => {
        try {
            const allPostCountRes = await makeRequest({
                url: `/api/post/all-post-counts`,
                method: 'GET',
            });
            if (allPostCountRes) {
                const publishedPostCounts = allPostCountRes.productChannelCounts.map(product => ({
                    productId: product.productId,
                    productName: product.productName,
                    totalPosts: product.channels.reduce((sum, channel) => {
                        return sum + (channel.postCounts.publishedEntries || 0);
                    }, 0),
                }));
                setTotalPosts(allPostCountRes?.groupedStatusCounts?.publishedEntries || 0);
                setProductStats(publishedPostCounts);
                setGroupedCardData(allPostCountRes);
            }
        } catch (error) {
            console.error('Failed to fetch all post count:', error);
        } finally {
            setLoadingPosts(false);
            setGroupedCardLoading(false);
            setDynamicCardLoading(false);
        }
    };

    const fetchGroupedPostStats = async () => {
        try {
            const likeCommentCount = await makeRequest({
                url: `/api/posts/like-comment-counts?publicationState=preview`,
                method: 'GET',
            });
            if (likeCommentCount) {
                setTotalLikes(likeCommentCount.total_likes || 0);
                setTotalComments(likeCommentCount.total_comments || 0);
                setProductCard(likeCommentCount);
            } else {
                setTotalLikes(0);
                setTotalComments(0);
            }
        } catch (error) {
            console.error('Error fetching grouped post stats:', error);
        } finally {
            setLoadingLikes(false);
            setLoadingComments(false);
        }
    };

    const fetchAllFollowersCount = async () => {
        try {
            const followerCount = await makeRequest({
                url: `/api/product/follower-counts?publicationState=preview`,
                method: 'GET',
            });
            if (followerCount?.products) {
                setProductFollowerCount(followerCount.products);
            }
        } catch (error) {
            console.error('Error fetching follower counts:', error);
        }
    };

    useEffect(() => {
        fetchAllPostCounts();
        fetchGroupedPostStats();
        fetchAllFollowersCount();
    }, []);

    const cardData = [
        {
            loading: loadingPosts,
            title: 'Total Posts',
            value: `${totalPosts || 0}`,
            icon: <FavoriteIcon color="primary" />,
        },
        {
            loading: loadingLikes,
            title: 'Total Likes',
            value: `${totalLikes || 0}`,
            icon: <CommentIcon color="secondary" />,
        },
        {
            loading: loadingComments,
            title: 'Total Comments',
            value: `${totalComments || 0}`,
            icon: <CommentIcon color="secondary" />,
        },
    ];

    const selectedProductCard = (productId) => {
        if (
            !productId ||
            !groupedCardData?.productChannelCounts ||
            !productCard?.products ||
            !productFollowerCount
        ) {
            return [];
        }

        const product = groupedCardData.productChannelCounts.find(item => item.productId == productId);
        const productStats = productCard.products.find(item => item.product_id == productId);
        const followerStats = productFollowerCount.find(item => item.productId == productId);

        return [
            {
                title: 'Total Posts',
                value: `${product?.total_product_published_posts || 0}`,
                icon: <FavoriteIcon color="primary" />,
                loading: dynamicCardLoading,
            },
            {
                title: 'Total Likes',
                value: `${productStats?.total_product_likes || 0}`,
                icon: <FavoriteIcon color="secondary" />,
                loading: loadingLikes,
            },
            {
                title: 'Total Comments',
                value: `${productStats?.total_product_comments || 0}`,
                icon: <CommentIcon color="action" />,
                loading: loadingComments,
            },
            {
                title: 'Total Followers',
                value: `${followerStats?.productFollowers || 0}`,
                icon: <CommentIcon color="action" />,
                loading: loadingComments,
            },
        ];
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
                px: 1,
            }}
        >
            <Container maxWidth="xl">
                <PageHeader title="Dashboard" icon={<DashboardIcon />} />

                <Grid container spacing={1} sx={{ mt: 2 }}>
                    {productId
                        ? selectedProductCard(productId).map((card, index) => (
                            <Grid key={`selected-card-${index}`} xs={12} sm={6} lg={3}>
                                <CountCard
                                    sx={{ height: '100%' }}
                                    title={card.title}
                                    value={card.value}
                                    loading={card.loading}
                                />
                            </Grid>
                        ))
                        : cardData.map((card, index) => (
                            <Grid key={`general-card-${index}`} xs={12} sm={6} lg={3}>
                                <CountCard
                                    sx={{ height: '100%' }}
                                    title={card.title}
                                    value={card.value}
                                    loading={card.loading}
                                />
                            </Grid>
                        ))}
                </Grid>

                {productId && (
                    <>
                        <Box sx={{ mt: 4 }}>
                            <Grid xs={12} md={6} lg={8}>
                                <ProductChannelStats productId={productId} />
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Grid xs={12} md={6} lg={8}>
                                <ProductPostStats productId={productId} />
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Grid xs={12} md={6} lg={8}>
                                <LikesCommentChart productId={productId} />
                            </Grid>
                        </Box>
                    </>
                )}

                {!productId && (
                    <Box sx={{ mt: 4 }}>
                        <Grid xs={12} md={6} lg={8}>
                            <Insights />
                        </Grid>
                    </Box>
                )}
            </Container>
        </Box>
    );
}

export default Dashboard;
