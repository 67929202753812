import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import makeRequest from '../../makeRequest';
import ProductList from './ProductList';
import ChannelList from './ChannelList';
import Tabs from '../../components/Tabs';
import { FB_APP } from '../../constant';
import Loader from '../../components/Loader';
import { useSelector } from 'react-redux';


function Settings({ currentProductId }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState([]);
    const authState = useSelector(state => state.auth);
    const userProducts = authState.user.userRoles?.map(item => item.product) || [];
    const userProductIds = userProducts.map(product => product.id);
    const filteredChannels = channels.filter(channel => userProductIds.includes(channel?.attributes?.product?.data?.id));

    const handleFacebookConnect = () => {
        const redirectUri = encodeURIComponent(`${window.location.href}`);
        const scopes = [
            'pages_show_list',
            'public_profile',
            'email',
            'business_management',
            'pages_read_engagement',
            'instagram_basic',
            'instagram_manage_insights',
            'ads_management',
            'catalog_management',
            'instagram_shopping_tag_products',
            'instagram_content_publish',
            'pages_manage_metadata',
            'instagram_manage_comments',
            'pages_manage_posts',
            'pages_manage_engagement',
            'pages_manage_instant_articles',
            'publish_video',
            'read_insights',
            // 'threads_manage_replies',
            // 'threads_read_replies',
        ];

        setLoading(true);
        const scopeStr = encodeURIComponent(scopes.join(','))
        const fbOAuthUrl = `https://www.facebook.com/v18.0/dialog/oauth?response_type=code&state=${Date.now()}&scope=${scopeStr}&display=popup&client_id=${FB_APP}&redirect_uri=${redirectUri}`;
        window.location.href = fbOAuthUrl;

    }

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');

        if (!code) return;

        setLoading(true);
        makeRequest({
            url: `/api/facebook?code=${code}&redirect_uri=settings?view=channels`,
            method: 'GET',
        }).then(response => {
            console.log('response: ', response);
            if (response?.data) {
                navigate('/settings?view=channels', { replace: true });
            }
        }).catch(error => {
            console.error('Error during Facebook login:', error);
        }).finally(() => {
            setLoading(false);
        });

    }, [location.search, navigate]);

    const fetchData = () => {
        setLoading(true);
        makeRequest({
            url: `/api/channels?populate=*&publicationState=preview`,
            method: 'GET',
        }).then(respData => {
                if (respData?.data) {
                    setChannels(respData.data);
                }
            })
            .catch(error => {
                console.error('Error fetching channels:', error);
            }).finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        fetchData();
    }, [authState])

    const tabs = [
        {
            key: 'channels',
            label: 'Channels',
            content: loading ? <Loader /> : <ChannelList
                channels={filteredChannels}
                handleFacebookConnect={handleFacebookConnect}
                currentProductId={currentProductId}
            />
        },
        {
            key: 'products',
            label: 'Products',
            content: loading ? <Loader /> : <ProductList currentProductId={currentProductId} />
        },
    ];


    return (
        <Box component="div">
            <Tabs
                tabs={tabs}
                defaultView="channels"
                showPageHeader
                pageTitle="Settings"
                pageIcon={<SettingsIcon />}
            />
        </Box>
    );
}

export default Settings;
