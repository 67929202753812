import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, useTheme, Tooltip, IconButton } from '@mui/material';
import MUIAutocomplete from '../../components/MUIAutoComplete';
import UploadFiles from './UploadFiles';
import MUIModal from '../../components/MUIModal';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Can } from '../../context/Can';
import { subject } from "@casl/ability";
import { parseNumber } from '../../helpers';
import { setGlobalMessage } from '../../Redux/slices/uiSlice';
import { useDispatch, useSelector } from "react-redux";

const ProductForm = ({ currentProductId, product, onSubmit, showUserFields }) => {
    const theme = useTheme();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [channels, setChannels] = useState([]);
    const [files, setFiles] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ email: '', role: 'Owner' });
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const dispatch = useDispatch();

    const validateFields = () => {
        const errors = {};

        if (!name.trim()) {
            errors.name = "Name is required.";
        }

        if (!description.trim()) {
            errors.description = "Description is required.";
        }

        if (!url.trim()) {
            errors.url = "URL is required.";
        } else {
            const urlPattern = /^(https?:\/\/)?([\w.-]+)+(:\d+)?(\/[\w.-]*)*\/?$/;
            if (!urlPattern.test(url)) {
                errors.url = "Please enter a valid URL.";
            }
        }

        // if (channels.length === 0) {
        //     errors.channels = "At least one channel must be selected.";
        // }

        if (files.length > 0) {
            const invalidFiles = files.filter(file => !file.name || !file.type);
            if (invalidFiles.length > 0) {
                errors.files = "One or more files are invalid.";
            }
        }

        // if (users.length === 0) {
        //     errors.users = "At least one user must be added.";
        // }

        return errors;
    };

    const  handleSubmit = (event) => {
        event.preventDefault();

        const errors = validateFields();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
        const isUpdating = !!product; 
        const productData = {
            name,
            description,
            url,
            channels,
            files,
            removedFiles,
            users
        };
        onSubmit(productData);
        dispatch(setGlobalMessage({
            message: isUpdating ? "Product updated successfully!" : "Product created successfully!",
            severity: "success"
        }));
    };

    // const handleAddUser = () => {
    //     setUsers([...users, { ...newUser, id: `temp-${Date.now()}` }]);
    //     setOpenAddUserDialog(false);
    // };
    const handleAddUser = () => {
        try {
            setUsers([...users, { ...newUser, id: `temp-${Date.now()}` }]);
            // dispatch(setGlobalMessage({message: "User will be invited shortly!",severity: "success"}));
            dispatch(setGlobalMessage({ message: "User added successfully!", severity: "success" }));
        } catch (error) {
            dispatch(setGlobalMessage({ message: "Failed to add user. Please try again.", severity: "error" }));
        } finally {
            setOpenAddUserDialog(false);
        }
    };
    // const handleDeleteUser = (id) => {
    //     setUsers(users.filter(user => user.id !== id));
    // };
    const handleDeleteUser = (id) => {
        try {
            setUsers(users.filter(user => user.id !== id));
            dispatch(setGlobalMessage({ message: "User deleted!", severity: "success" }));
        } catch (error) {
            dispatch(setGlobalMessage({ message: "Failed to delete user. Please try again.", severity: "error" }));
        }
    };
    const handleOpenAddUserDialog = () => {
        setOpenAddUserDialog(true);
    };

    const handleCloseAddUserDialog = () => {
        setOpenAddUserDialog(false);
    };

    useEffect(() => {
        if (product) {
            setName(product.attributes.name || '');
            setDescription(product.attributes.details || '');
            setUrl(product.attributes.url || '');
            setChannels(
                product.attributes.channels.data.map((cItem) => ({
                    id: cItem.id,
                    label: `${cItem.attributes['display_name']} - ${cItem.attributes.channel_type}`,
                    data: cItem,
                }))
            );
            setUsers(product.attributes.users || []);
        }
    }, [product]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                margin: 'auto',
            }}
        >
            <Box sx={{ mt: 1, flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                            <TextField
                                label="Name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setValidationErrors((prev) => ({ ...prev, name: '' }));
                                }}
                                error={!!validationErrors.name}
                                helperText={validationErrors.name}
                                required
                            />
                            <TextField
                                label="Description"
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    setValidationErrors((prev) => ({ ...prev, description: '' }));
                                }}
                                error={!!validationErrors.description}
                                helperText={validationErrors.description}
                                multiline
                                rows={6}
                                required
                            />
                            <TextField
                                label="URL"
                                value={url}
                                onChange={(e) => {
                                    setUrl(e.target.value);
                                    setValidationErrors((prev) => ({ ...prev, url: '' }));
                                }}
                                error={!!validationErrors.url}
                                helperText={validationErrors.url}
                                type="url"
                                required
                            />
                            <MUIAutocomplete
                                multiple
                                label="Channels"
                                selectedValue={channels}
                                apiType="REST"
                                apiRequest={{
                                    url: `/api/channels?populate=*&publicationState=preview`,
                                    method: 'GET',
                                }}
                                fieldKey="display_name"
                                onSelect={(channelObj) => {
                                    setChannels(channelObj);
                                    setValidationErrors((prev) => ({ ...prev, channels: '' }));
                                }}
                                autoHighlight
                                error={!!validationErrors.channels}
                                helperText={validationErrors.channels}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                            <Box
                                sx={{
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderRadius: '8px',
                                    padding: 2,
                                }}
                            >
                                <UploadFiles
                                    onFilesChange={(fileList) => {
                                        setFiles(fileList);
                                        setValidationErrors((prev) => ({ ...prev, files: '' }));
                                    }}
                                    existingMedia={product?.attributes?.media?.data || []}
                                    onRemovedFiles={setRemovedFiles}
                                />
                                {validationErrors.files && (
                                    <Box sx={{ color: 'error.main', mt: 1 }}>
                                        {validationErrors.files}
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                        <Can
                            I="manage"
                            this={subject("ManageProducts", { productId: parseNumber(product?.id || currentProductId) })}
                            field="productId"
                        >
                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <Button type="submit" variant="contained" color="primary">
                                    {product ? 'Update Product' : 'Create Product'}
                                </Button>
                            </Box>
                        </Can>
                    </Grid>
                    {showUserFields && <Grid item xs={12}>
                        <Box>
                            <Can
                                I="manage"
                                this={subject("ManageUsers", { productId: parseNumber(currentProductId) })}
                                field="productId"
                            >
                                <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <Button variant="outlined" color="primary" onClick={handleOpenAddUserDialog}>
                                        Add User
                                    </Button>
                                </Box>
                            </Can>
                            <TableContainer component={Box} sx={{ mt: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user) => (
                                            <TableRow key={user.id}>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>{user.role}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            variant="contained"
                                                            onClick={() => handleDeleteUser(user.id)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>

            <MUIModal
                open={openAddUserDialog}
                handleClose={() => {
                    handleCloseAddUserDialog();
                }}
                title="Add New User"
            >
                <Stack spacing={2}>
                    <TextField
                        label="Email"
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        required
                    />
                    <TextField
                        select
                        label="Role"
                        value={newUser.role}
                        onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                        required
                    >
                        <MenuItem value="Owner">Owner</MenuItem>
                        <MenuItem value="Editor">Editor</MenuItem>
                        <MenuItem value="Viewer">Viewer</MenuItem>
                    </TextField>
                </Stack>
                <DialogActions>
                    {/* <Button onClick={handleCloseAddUserDialog}>Cancel</Button> */}
                    <Can
                        I="manage"
                        this={subject("ManageUsers", { productId: parseNumber(currentProductId) })}
                        field="productId"
                    >
                        <Button onClick={handleAddUser} color="primary">Add</Button>
                    </Can>
                </DialogActions>
            </MUIModal>
        </Box>
    );
};

export default ProductForm;
