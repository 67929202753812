import { Box, Container } from '@mui/material';
import React from 'react'

function NotFound() {
  return (
    <Box
    component="main"
    sx={{
        flexGrow: 1,
        py: 8,
        px: 1
    }}
>
    <Container maxWidth="xl">
    <div style={{textAlign:'center'}}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
    </Container>
    </Box>
  )
}

export default NotFound;