import React from 'react'
import { Navigate } from "react-router-dom";
import MUILoader from "../components/MUILoader";
import { useSelector } from 'react-redux';

function PublicRoute({ element, restricted }) {
    const uiState = useSelector((state) => state.ui);
    const state = useSelector((state) => state.auth);
    const user = state.user;

    if (uiState.loading) {
        return <MUILoader open />;
    }
    if (user && restricted) {
        return <Navigate to="/dashboard" />;
    }
    return element;
};

export default PublicRoute