import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import MUILoader from "../components/MUILoader";
import { useSelector } from "react-redux";


const PrivateRoute = () => {
  const uiState = useSelector((state) => state.ui);
  const authState = useSelector((state) => state.auth);
  const user = authState.user;
  const productId = useSelector((state) => state.auth.productId);

  if (uiState.loading) {
    return <MUILoader open />;
  }
  if (user) {
    return <Outlet />;
  }
  return <Navigate to="/login" replace/>;
};

export default PrivateRoute;
