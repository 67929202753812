import React, { useState } from "react";
import { Form, Input, Button, message, Card, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import makeRequest from "../../makeRequest";
import { useDispatch } from "react-redux";

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleForgotPassword = async (values) => {
    setIsLoading(true);

    try {
      await makeRequest(
        {
          url: `/api/auth/forgot-password`,
          method: "POST",
          data: { email: values.email },
        },
        false
      );

      message.success("Password reset email sent! Check your inbox.");
      setTimeout(() => navigate("/login"), 2000);
      
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to send reset email. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component="main" sx={{ display: "flex", flex: "1 1 auto" }}>
      <Grid container sx={{ flex: "1 1 auto" }}>
        <Grid
          xs={12}
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundColor: "background.paper",
              flex: "1 1 auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ maxWidth: 500, px: 3, py: "100px", width: "100%" }}>
              <Typography component="h3" variant="h4" align="center">
                Forgot Password
              </Typography>

              <Card>
                <Form layout="vertical" autoComplete="off" onFinish={handleForgotPassword}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email." },
                      { type: "email", message: "Please enter a valid email address." },
                    ]}
                  >
                    <Input placeholder="Enter your email" disabled={isLoading} />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={isLoading} block>
                      {isLoading ? "Sending..." : "Send Reset Email"}
                    </Button>
                  </Form.Item>
                </Form>

                <Typography.Paragraph align="center">
                  <Link to="/login">Back to Sign In</Link>
                </Typography.Paragraph>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgetPassword;
