export const AVATAR_API = "https://ui-avatars.com/api";
// export const API = "http://localhost:1337/api";
//export const API = "https://marketing-admin.dev.ceegees-inc.com/api";
export const AUTH_TOKEN = 'access_token' //"authToken";
export const BEARER = "Bearer";
// export const API_TOKEN ="4c8f7908dafb851308f27612d24c32db5bb8cc5bef42a3e45763da6163aed64f31c0722888cf686a01d3dcaf38e8300f950f9985557c00416988ef6e79f697e7f2573da3c75fa33895cb6721da516c02d3d6fd407b6d09058defb47a8296d73f04526d7db7478f71fb85c8336af91449834d5a32c26fc9b03a68651c01b716bc";
//server
//export const API_TOKEN ="af1983a68e18ea400996280125b262f259c967fd4bb583707a8fcd18e092a0c3aced41123150ffe0333418824e0eb8ba2859a86691f5f026ce66401cc31e297f9892e183f7fe6b2d19dc6f5ae37db6f6fb82f0bba5935d4b58b9729e6ef9bf446dada72b3c7723c435e9a178f4e1a2755fc97a015727a316a233acf76560f728";
export const BASE_URL = window.BASE_URL;
export const FB_APP = window.FB_APP;
export const palleonBaseURL = window.PALLEON_BASE_URL;

export const userRoles = {
    // SUPER_ADMIN: 'Super Admin',
    // ADMIN: 'Admin',
    OWNER: 'Owner',
    MANAGER: 'Manager',
    ANALYST: 'Analyst',
}