import { useEffect, useRef } from 'react';
import {
    Box,
    IconButton,
    Stack,
    useMediaQuery,
    Typography, MenuItem, FormControl, TextField 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { alpha } from '@mui/material/styles';
import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from '../theme';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setProductId } from "../Redux/slices/authSlice";
import { useDataContext } from '../context/DataContext';

export const TopNav = (props) => {
    const { products } = useDataContext();
    const authState =  useSelector(state=> state.auth);
    const userProducts = authState.user?.userRoles?.map(item=>item.product) || [];
    const filteredProducts =  products.filter(fItem => userProducts.find(pItem=> fItem.id == pItem.id))
    const { isOpen, onToggleSidebar } = props;
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const selectFieldRef = useRef();
    const location = useLocation();
    const selectedProduct = useSelector((state) => state.auth.productId);
    const dispatch = useDispatch();

    const handleProductChange = (event) => {
        let prodValue = event.target.value;
        if (event.target.value === 'All') {
            prodValue = '';
        }
        dispatch(setProductId(prodValue));
    };

    const shouldShowProductControls = ['/dashboard', '/schedule'].includes(location.pathname);
    const isUserRolesEmpty = authState.user?.userRoles ? authState.user?.userRoles?.length > 0 : false;
    return (
        <>
            <Box
                component="header"
                sx={{
                    backdropFilter: 'blur(6px)',
                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                    position: 'fixed',
                    left: {
                        lg: `${isOpen ? SIDE_NAV_WIDTH : 65}px`,
                        sm: 0,
                        xs: 0
                    },
                    right: {
                        sm: 0,
                        xs: 0
                    },
                    top: 0,
                    width: {
                        lg: `calc(100% - ${isOpen ? SIDE_NAV_WIDTH : 65}px)`
                    },
                    zIndex: (theme) => theme.zIndex.appBar,
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent={shouldShowProductControls && selectedProduct ? 'space-between' : 'flex-end'}
                    sx={{
                        minHeight: TOP_NAV_HEIGHT,
                        px: isLargeScreen ? 4 : 1 
                    }}
                >
                    {!isLargeScreen && (
                        <Stack alignItems="center" direction="row" spacing={2} sx={{ mr:'auto'}}>
                            <IconButton onClick={onToggleSidebar}>
                                <MenuIcon />
                            </IconButton>
                        </Stack>
                    )}
                    {isUserRolesEmpty && shouldShowProductControls && selectedProduct && (
                        <Box
                        sx={{
                            width:'100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h6">
                            {products?.find((product) => product.id == selectedProduct)?.attributes?.name || ''}
                        </Typography>
                        </Box>
                    )}
                    {isUserRolesEmpty && shouldShowProductControls && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <FormControl variant="outlined" sx={{ minWidth: 200 }} size='small'>
                                <TextField
                                    size='small'
                                    inputRef={selectFieldRef}
                                    select
                                    name='productFilter'
                                    value={selectedProduct}
                                    onChange={handleProductChange}
                                    label="Select Product"
                                >
                                    {/* <MenuItem value="All">
                                        <em>All</em>
                                    </MenuItem> */}
                                    {filteredProducts?.map((product) => (
                                        <MenuItem
                                            key={product.id || product.attributes?.id}
                                            value={product.id || product.attributes?.id}
                                        >
                                            {product.name || product.attributes?.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    )}
                </Stack>
            </Box>
        </>
    );
};
