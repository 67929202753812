import React, { useState } from 'react';
import {Avatar,Button,CssBaseline,Grid,Box,Typography,Container,IconButton,InputAdornment,TextField} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BASE_URL } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import makeRequest from '../../makeRequest';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../Redux/slices/authSlice';
import { Visibility, VisibilityOff, LockOutlined as LockOutlinedIcon } from '@mui/icons-material';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }


const defaultTheme = createTheme();

export default function SignUp() {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    });

    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const validate = (values) => {
        let errors = {};

        if (!values.firstName.trim()) {
            errors.firstName = "First Name is required";
        }
        if (!values.lastName.trim()) {
            errors.lastName = "Last Name is required";
        }
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
            errors.email = "Invalid email address";
        }
        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
        } else if (!/(?=.*[A-Za-z])(?=.*\d)/.test(values.password)) {
            errors.password = "Password must contain at least one letter and one number.";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validate(values)) {
            return;
        }

        setIsLoading(true);
        setApiError("");

        const payload = {
            email: values.email,
            password: values.password,
            username: values.firstName,
        };
        // const formdata = new FormData(event.currentTarget);
        // const value = {
        //     email: formdata.get('email'),
        //     password: formdata.get('password'),
        //     username: formdata.get('firstName')
        // };
        try {
            const response = await makeRequest({
                url: `/api/auth/local/register`,
                method: "POST",
                data: JSON.stringify(payload),
                headers: { "Content-Type": "application/json" },
            }, false);

            if (response?.error) {
                throw response?.error;
            } else if (response && response?.jwt) {
                let productId = response.user?.userRoles?.[0]?.product?.id || null;
                dispatch(loginSuccess({
                    token: response?.jwt,
                    user: response?.user,
                    productId: productId,
                }));
                navigate(productId ? "/dashboard" : "/settings");
            }
        } catch (error) {
            console.error("Signup Error:", error?.response?.data || error);
            
            if (error?.response?.data?.error?.message.includes("Email already taken")) {
                alert("This user already exists!");
            } else {
                setApiError(error?.response?.data?.error?.message || "Something went wrong!");
            }
        }finally {
            setIsLoading(false);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    value={values.firstName}
                                    onChange={handleChange}
                                    error={!!formErrors.firstName}
                                    helperText={formErrors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    error={!!formErrors.lastName}
                                    helperText={formErrors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    error={!!formErrors.email}
                                    helperText={formErrors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    error={!!formErrors.password}
                                    helperText={formErrors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleTogglePassword} edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {apiError && <Typography color="error" sx={{ mt: 2 }}>{apiError}</Typography>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? "Signing Up..." : "Sign Up"}
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                {/* <AppRoutes> */}
                                {/* <Routes> */}
                                    <Link to="/login" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                {/* </Routes> */}
                                {/* </AppRoutes> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* <Copyright sx={{ mt: 5 }} /> */}
            </Container>
        </ThemeProvider>
    );
}



