import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import makeRequest from "../makeRequest";
import { setGlobalMessage } from "../Redux/slices/uiSlice";
import { useDispatch, useSelector } from "react-redux";

const HandleInvite = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inviteDetails, setInviteDetails] = useState(null);
    const dispatch = useDispatch();

    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    console.log('token: ', token);

    useEffect(() => {
        const validateToken = async () => {
            makeRequest({
                url: `/api/products/validate-invite-token`,
                method: 'POST',
                data: { token }
            }, false)
                .then(response => {
                    console.log('valid response: ', response);
                    setInviteDetails(response.data);
                }).catch(err => {
                    console.error(err);
                    setError("Invalid or expired token.");
                }).finally(() => {
                    setLoading(false);
                })
        };

        if (token) validateToken();
    }, [token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);

        if (inviteDetails && inviteDetails.valid && (!password || !confirmPassword)) {
            setError("Both password fields are required.");
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        setLoading(true);

        const payload = inviteDetails.valid
            ? { token, password, passwordConfirmation: confirmPassword }
            : { token };

        makeRequest({
            url: `/api/products/accept-invitation`,
            method: 'POST',
            data: payload
        }, false)
            .then(response => {
                console.log('Accept response: ', response);
                dispatch(setGlobalMessage({ message: "Invitation accepted successfully! Redirecting to dashboard...", severity: "success" }));
                // setSuccess("Invitation accepted successfully! Redirecting to dashboard...");
                setTimeout(() => navigate("/login"), 3000);
            }).catch(err => {
                console.error(err);
                setError(err.response?.data?.message || "An error occurred while accepting the invitation.");
            }).finally(() => {
                setLoading(false);
            })

    };

    return (
        <Box
            sx={{
                maxWidth: 400,
                margin: "auto",
                mt: 10,
                p: 4,
                border: "1px solid #ddd",
                borderRadius: 2,
                boxShadow: 2,
            }}
        >
            <Typography variant="h6" gutterBottom>
                Frappe Invitation
            </Typography>
            <Typography variant="title" gutterBottom>
                {inviteDetails && `Join as ${inviteDetails?.productRole?.role} for the product ${inviteDetails?.productRole?.product?.name}`}
            </Typography>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
            {inviteDetails && inviteDetails.valid && (
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Set Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                        sx={{ mt: 2 }}
                    >
                        {loading ? "Submitting..." : "Accept Invitation"}
                    </Button>
                </form>
            )}
        </Box>
    );
};

export default HandleInvite;
