import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
    Box, Paper, IconButton, Tooltip, TableFooter, TablePagination, Stack,
    Button, TextField, MenuItem,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../components/Loader';
import MUIModal from '../../components/MUIModal';
import makeRequest from '../../makeRequest';
import MUILoader from '../../components/MUILoader';
import { Can } from '../../context/Can';
import { subject } from "@casl/ability";
import { parseNumber } from '../../helpers';
import { setGlobalMessage } from '../../Redux/slices/uiSlice';
import { useDispatch, useSelector } from "react-redux";

const UserList = ({ currentProductId, onUpdateAddedUsers, addedUsers = [], initialProdUsers = [], onSubmit, onDelete, onResendInvite }) => {

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [prodUsers, setProdUsers] = useState(initialProdUsers || []);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [newUser, setNewUser] = useState({ email: '', role: '' });
    const [viewModal, setViewModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isProdUser, setIsProdUser] = useState(false);
    const dispatch = useDispatch();
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        setProdUsers(initialProdUsers);
    }, [initialProdUsers]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseNumber(event.target.value));
        setPage(1);
    };

    const handleAddClose = () => {
        setAddDialogOpen(false);
        setNewUser({ email: '', role: '' });
    };

    const handleDeleteAddedUser = (userId) => {
        onUpdateAddedUsers(addedUsers.filter(user => user.id !== userId));
        dispatch(setGlobalMessage({ message: "User removed successfully!", severity: "success" }));
    };

    const handleConfirmDelete = (user) => {
        setSelectedUser(user);
        setViewModal(true);
    }

    const handleDelete = async (id) => {
        if (!id) return;

        setLoading(true);
        await makeRequest({
            method: 'DELETE',
            url: `/api/product-roles/${id}`,
        }).then(respData => {
            if (respData) {
                onDelete();
                handleDeleteModalClose();
                dispatch(setGlobalMessage({ message: "User deleted successfully!", severity: "success" }));
            }
        }).catch(err => {
            console.error(`Product delete Error`, err);
            dispatch(setGlobalMessage({ message: "Failed to delete user. Please try again.", severity: "error" }));
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleDeleteModalClose = () => {
        setViewModal(false);
        if (selectedUser) {
            setSelectedUser(null);
        }
    }

    const handleAddOpen = () => {
        setIsEditMode(false);
        setNewUser({ email: '', role: '' });
        setAddDialogOpen(true);
    };

    const handleEditOpen = (user, isFromProdUsers) => {
        setIsEditMode(true);
        setIsProdUser(isFromProdUsers);

        if (isFromProdUsers) {
            setSelectedUser(user);
            setNewUser({
                email: user?.user?.email || '',
                role: user?.role || '',
            });
        } else {
            setSelectedUser(user);
            setNewUser({
                email: user.email || '',
                role: user.role || '',
            });
        }
        setAddDialogOpen(true);
    };

    const handleAddUser = () => {
        if (!validateEmail(newUser.email)) {
            setEmailError("Please enter a valid email address.");
            return;
        }
    
        setEmailError(''); 

        if (isEditMode) {
            if (isProdUser) {
                setLoading(true);
                makeRequest({
                    url: `/api/product-roles/${selectedUser.id}`,
                    method: 'PUT',
                    data: { data: { role: newUser.role } },
                })
                    .then(() => {
                        const updatedProdUsers = prodUsers.map((user) =>
                            user.id === selectedUser.id
                                ? { ...user, attributes: { ...user.attributes, role: newUser.role } }
                                : user
                        );
                        setProdUsers(updatedProdUsers);
                        dispatch(setGlobalMessage({ message: "User updated successfully!", severity: "success" }));
                        handleAddClose();
                    })
                    .catch((error) => {
                        console.error('Error updating user:', error);
                        dispatch(setGlobalMessage({ message: "Failed to update user.", severity: "error" }));
                    })                    
                    .finally(() => setLoading(false));
            } else {
                const updatedAddedUsers = addedUsers.map((user) =>
                    user.id === selectedUser.id ? { ...user, ...newUser } : user
                );
                onUpdateAddedUsers(updatedAddedUsers);
                dispatch(setGlobalMessage({ message: "User updated successfully!", severity: "success" }));
                handleAddClose();
            }
        } else {
            const userToAdd = { ...newUser, id: `temp-${Date.now()}` }; // Temporary ID
            onUpdateAddedUsers([...addedUsers, userToAdd]);
            // dispatch(setGlobalMessage({ message: "User added successfully!", severity: "success" }));
            handleAddClose();
        }
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            {isLoading && <MUILoader />}
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Loader />
                </Box>
            ) : (
                <>
                    <Can
                        I="manage"
                        this={subject("ManageUsers", { productId: parseNumber(currentProductId)})}
                        field="productId"
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                            <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddOpen}>
                                Add User
                            </Button>
                        </Box>
                    </Can>
                    <TableContainer component={Paper} sx={{ marginTop: 0.5 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>
                                        <Typography variant="body1" fontWeight="bold">Email</Typography>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Typography variant="body1" fontWeight="bold">Role</Typography>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Typography variant="body1" fontWeight="bold">Status</Typography>
                                    </TableCell>
                                        <Can
                                            I="manage"
                                            this={subject("ManageUsers", { productId: parseNumber(currentProductId) })}
                                            field="productId"
                                        >
                                        <TableCell align='center'>
                                            <Typography variant="body1" fontWeight="bold">Actions</Typography>
                                        </TableCell>
                                    </Can>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prodUsers?.length === 0 && addedUsers?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                sx={{ py: 4, textAlign: 'center' }}
                                            >
                                                No users found.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {prodUsers?.map(user => (
                                    <TableRow key={user.id}>
                                        <TableCell align='left'>{user.user?.email || '-'}</TableCell>
                                        <TableCell align='center'>{user?.role || '-'}</TableCell>
                                        <TableCell align='center'>{user?.invitationStatus || '-'}</TableCell>
                                        <Can
                                            I="manage"
                                            this={subject("ManageUsers", { productId: parseNumber(prodUsers?.product?.id === currentProductId ? prodUsers?.product?.id : currentProductId) })}
                                            field="productId"
                                        >
                                            <TableCell align='center'>
                                                {user?.invitationStatus !== 'Accepted' && <Tooltip title="Edit">
                                                    <IconButton onClick={() => handleEditOpen(user, true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                                {user?.invitationStatus !== 'Accepted' && <Tooltip title="Resend Invite" sx={{ marginRight: 1 }}>
                                                    <IconButton variant="contained" onClick={() => onResendInvite(user)}>
                                                        <SendIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        variant="contained"
                                                        onClick={() => handleConfirmDelete(user)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </Can>
                                    </TableRow>
                                ))}
                                {addedUsers?.map(user => (
                                    <TableRow key={user.id}>
                                        <TableCell align='left'>{user.email}</TableCell>
                                        <TableCell align='center'>{user.role}</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <Can
                                            I="manage"
                                            this={subject("ManageUsers", { productId: currentProductId })}
                                            field="productId"
                                        >
                                            <TableCell align='center'>
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={() => handleEditOpen(user, false)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        variant="contained"
                                                        onClick={() => handleDeleteAddedUser(user.id)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </Can>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={totalUsers}
                                        rowsPerPage={rowsPerPage}
                                        page={page - 1}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                        <Can
                            I="manage"
                            this={subject("ManageUsers", { productId: parseNumber(currentProductId) })}
                            field="productId"
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                {addedUsers.length > 0 && <Button variant="contained" color="primary" onClick={() => onSubmit(addedUsers)}>
                                    Save Users
                                </Button>}
                            </Box>
                    </Can>
                </>
            )}

            <MUIModal
                open={addDialogOpen}
                handleClose={handleAddClose}
                title={isEditMode ? "Edit User" : "Add New User"}
            >
                <Stack spacing={2} sx={{ mt: 1 }}>
                    <TextField
                        label="Email"
                        fullWidth
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        disabled={isEditMode}
                        error={!!emailError}
                        helperText={emailError}
                    />
                    <TextField
                        select
                        label="Role"
                        fullWidth
                        value={newUser.role}
                        onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                    >
                        <MenuItem value="Owner">Owner</MenuItem>
                        <MenuItem value="Manager">Manager</MenuItem>
                        <MenuItem value="Analyst">Analyst</MenuItem>
                    </TextField>
                </Stack>
                <Can
                    I="manage"
                    this={subject("ManageUsers", { productId: parseNumber(currentProductId) })}
                    field="productId"
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                        <Button 
                            variant="outlined" 
                            onClick={handleAddUser}
                            disabled={!newUser?.email || !newUser?.role}
                        >
                            {isEditMode ? "Save Changes" : "Add"}
                        </Button>
                    </Box>
                </Can>
            </MUIModal>


            {selectedUser && viewModal && <MUIModal
                open={viewModal}
                handleClose={() => {
                    handleDeleteModalClose()
                }}
                title="Do you want to proceed?"
            >
                <Box pt={1}>
                    <Typography variant='title'>
                        {`You are about to delete the user ${selectedUser?.user?.email}. This action cannot be undone.`}
                    </Typography>

                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} flexWrap="wrap" py={1}>
                        <Button
                            size="small"
                            variant="outlined"
                            type="submit"
                            onClick={handleDeleteModalClose}
                            sx={{ mt: 1 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            color='error'
                            variant="contained"
                            type="submit"
                            onClick={() => handleDelete(selectedUser?.id)}
                            sx={{ mt: 1 }}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </MUIModal>}
        </Box>
    );
};

export default UserList;
