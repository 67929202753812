import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ProductForm from './ProductForm';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import makeRequest from '../../makeRequest';
import Tabs from '../../components/Tabs';
import Loader from '../../components/Loader';
import UserList from './UserList';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToUser, setProductId } from '../../Redux/slices/authSlice';
import { fetchGlobalData } from '../../Redux/slices/dataSlice';
import { setGlobalMessage } from '../../Redux/slices/uiSlice';

const Product = ({ currentProductId }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [prodData, setProdData] = useState(null);
    const [userData, setUserData] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);
    const [existingProdUsers, setExistingProdUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);
    const authState = useSelector(state => state.auth);


    const handleUpdateAddedUsers = (updatedUsers) => {
        setAddedUsers(updatedUsers);
    };

    const fetchExistingUsers = async () => {
        try {
            const response = await makeRequest({
                url: `/api/product-roles?productId=${id}&page=${page}&pageSize=${rowsPerPage}`,
                // url: '/api/product-roles?populate=*&publicationState=preview&sort[0]=id:desc',
                method: 'GET',
            });

            if (response) {
                setAddedUsers([]);
                setExistingProdUsers(response?.results || []);
                setTotalUsers(response?.pagination.total);
            }
        } catch (error) {
            console.error('Error fetching existing users:', error);
        }
    };

    const fetchData = () => {
        setLoading(true);
        makeRequest({
            url: `/api/products/${id}?populate=*&publicationState=preview`,
            method: 'GET',
        })
            .then(respData => {
                if (respData?.data) {
                    setProdData(respData.data);
                    fetchExistingUsers();
                }
            })
            .catch(error => console.error('Error fetching product:', error))
            .finally(() => setLoading(false));
    };

    const handleSubmit = async (productData) => {

        if (!productData || !productData.name || !productData.description || !productData.url) {
            console.error('Product data is missing');
            return;
        }

        const pData = productData?.channels || [];
        const existingChannelIds = prodData ? prodData.attributes.channels.data.map(c => c.id) : [];
        const connectChannels = pData.map(cItem => ({
            id: cItem.id,
            position: { end: true },
        }));
        const disconnectChannels = existingChannelIds
            .filter(id => !pData.some(c => c.id === id))
            .map(id => ({ id }));

        let postData = {
            name: productData.name,
            details: productData.description,
            url: productData.url,
            channels: {
                connect: connectChannels,
                disconnect: disconnectChannels,
            },
        };

        if (prodData) {
            const removedFiles = productData?.removedFiles || [];
            postData = { ...postData, id: prodData.id, removedFiles };
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(postData));
        if (productData?.files?.length) {
            for (const file of productData.files) {
                formData.append('files', file);
            }
        }

        setIsLoading(true);
        try {
            const productResp = await makeRequest({
                method: prodData ? 'PUT' : 'POST',
                url: prodData ? `/api/products/${prodData.id}` : '/api/products',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (productResp?.data) {
                const pId = productResp.data.id;
                // console.log('productResp.data: ', productResp.data);
                if(!authState?.productId){
                    dispatch(setProductId(pId));
                    // document.location.reload();
                }
                if(!prodData?.id){
                    dispatch(addProductToUser(productResp.data));
                }
                dispatch(fetchGlobalData());
                dispatch(setGlobalMessage({message: `Product ${ !prodData?.id ? 'created' : 'updated' } successfully!`, severity:"success"}));
                navigate(`/settings/product/${pId}/edit`,{ replace: true })
            }
        } catch (err) {
            console.error('Submit error:', err);
            dispatch(setGlobalMessage({message:"Something went wrong",severity:"error"}));
        } finally {
            setIsLoading(false);
        }
    };

    const handleProdUserSubmit = async (userData = []) => {
        if (userData.length === 0) {
            return;
        }
        const postData = {
            productId: id,
            users: userData.map(uObj => ({ email: uObj.email, role: uObj.role }))
        }
        setIsLoading(true);
        await makeRequest({
            method: 'POST',
            url: `/api/products/invite-users`,
            data: postData,

        }).then(respData => {
            if (respData) {
                fetchData();
            }
            dispatch(setGlobalMessage({message: "User will be invited shortly!",severity: "success"}));
            // dispatch(setGlobalMessage({ message: "Users saved successfully!", severity: "success" }));
        }).catch(err => {
            console.error(`Product submit Error`, err);
        }).finally(() => {
            setIsLoading(false);
        });
    }

        const handleResendInvite = (userData) => {
            const postData = {
                productId: userData?.product?.id,
                userId: userData?.user?.id,
            };
            setLoading(true);
            makeRequest({
                url: `/api/products/resend-invitation`,
                method: 'POST',
                data: postData,
            })
                .then(() => {
                    fetchData();
                })
                .catch(error => {
                    console.error('Error re-sending invitation:', error);
                }).finally(() => {
                    setLoading(false);
                });
        };
  
    useEffect(() => {
        if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // const activeTab = location.pathname;
    // console.log('activeTab: ', activeTab);

    const tabs = [
        {
            key: 'product-detail',
            label: 'Product Detail',
            content: loading ? <Loader /> : <ProductForm
                currentProductId={id ? id : currentProductId}
                product={prodData}
                onSubmit={handleSubmit}
                />,
                disabled:false
        },
        {
            key: 'users',
            label: 'Users',
            content: <UserList
                onUpdateAddedUsers={handleUpdateAddedUsers}
                addedUsers={addedUsers}
                initialProdUsers={existingProdUsers}
                onSubmit={handleProdUserSubmit}
                onDelete={fetchData}
                onResendInvite={handleResendInvite}
                currentProductId={id ? id : currentProductId}
            />,
            disabled: id ? false : true
        },
    ];

    return (
        <Box component="div">
            <Tabs
                tabs={tabs}
                defaultView="product-detail"
                showPageHeader
                pageTitle={`${id ? 'Edit' : 'Create'} Product`}
                pageIcon={id ? <EditIcon /> : <AddIcon />}
            // pageHeadRight={<button>Action</button>}
            />
        </Box>
    );
};


export default Product;
