import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Access Denied</h1>
      <p>You don't have permission to access this page.</p>
      {/* <Button variant="contained" onClick={() => navigate("/dashboard")}>
        Go to Dashboard
      </Button> */}
    </div>
  );
};

export default Unauthorized;
