import axios from 'axios';
import { BASE_URL } from './constant';
import { getToken } from './helpers';


// axios.interceptors.request.use(function (config) {
//   config.headers['Authorization'] = 'bearer ' + localStorage.getItem('access_token');
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('access_token');
      window.location.href = '/login';
    } else {
      console.error('Error in response:', error);
      return Promise.reject(error);
    }
  }
);

const makeRequest = async (config, withAuth = true) => {
  const defaultConfig = {
    baseURL: BASE_URL,
    headers: {},
  };
  const authToken = getToken();
  const mergedConfig = { ...defaultConfig, ...config };

  if (withAuth) {
    mergedConfig.headers['Authorization'] = `Bearer ${authToken}`;
  }
  try {
    const response = await axios(mergedConfig);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export default makeRequest;
