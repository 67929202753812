import Unauthorized from "../pages/Unauthorized";
import { Can } from "./Can";
import Box from "@mui/material/Box";

export const RouteCan = (props) => {
  const { children, ...extra } = props;

  return (
    <>
      <Can not {...extra}>
        <Box py={8}>
          <Unauthorized />
        </Box>
      </Can>
      <Can {...extra}>
        {children}
      </Can>
    </>
  );
};
