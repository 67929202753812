import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Input, Button, message, Card } from "antd";
import axios from "axios";
import { setGlobalMessage } from "../../Redux/slices/uiSlice";
import { useDispatch } from "react-redux";
import { Link,useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (!password) {
    //   message.error("Please enter a new password.");
      dispatch(setGlobalMessage({ message: "Please enter a new password.", severity: "error" }));
      return;
    }

    if (!code) {
      message.error("Invalid or missing reset token.");
      return;
    }

    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/reset-password`, {
        code,
        password,
        passwordConfirmation: password,
      });
    //   dispatch(setGlobalMessage({ message: "Password reset successful! You can now log in.", severity: "success" }));
      message.success("Password reset successful! You can now log in.");
      setPassword("");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      console.error("Error resetting password:", error);
    //   dispatch(setGlobalMessage({ message: "Failed to reset password. Please try again.", severity: "error" }));
      message.error(error.response?.data?.error?.message || "Failed to reset password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card title="Reset Password" style={{ maxWidth: 400, margin: "auto", marginTop: 50 }}>
      <Form layout="vertical">
        <Form.Item label="New Password">
          <Input.Password
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={handleResetPassword} loading={isLoading} block>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ResetPassword;
