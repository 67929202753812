import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Stack,
    SvgIcon,
    Typography,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Chip,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStatusBgColor, parseNumber } from '../../helpers';
import { Can } from '../../context/Can';
import { subject } from "@casl/ability";
import { setGlobalMessage } from '../../Redux/slices/uiSlice';
import { useDispatch, useSelector } from "react-redux";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import { differenceInHours } from 'date-fns';

export const PostCard = (props) => {
    const { postData, dataType, onEdit, onDelete } = props;
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const dispatch = useDispatch();
    const uiState = useSelector((state) => state.ui);
    // const [editedPost, setEditedPost] = useState(postData); // Store editable post data
    // const [openEditDialog, setOpenEditDialog] = useState(false); // Define the state for controlling the edit dialog
    // useEffect(() => {
    //     // This effect runs on every render, and we reset the post data whenever `postData` changes
    //     setEditedPost(postData);
    // }, [postData]);

    if (!postData) {
        return null;
    }

    const normalizeData = (data, type) => {
        if (type === 'scheduledPosts') {
            return {
                id: data.id,
                content: data.content,
                tm_scheduled: data.tm_scheduled,
                tm_posted: data.tm_posted,
                channel: data.channel,
                product: data.channel?.product,
                status: data.status,
                publishedAt: data.publishedAt,
                postUrl: null,
                type: data.type,
                media: data?.media || null,
            };
        } else if (type === 'posts') {
            const { attributes } = data;
            // console.log('attributes: ', attributes);
            const channelData = attributes.channel?.data?.attributes;
            const productData = {...channelData?.product?.data?.attributes, id:channelData?.product?.data?.id};
            return {
                id: data.id,
                content: attributes.content,
                tm_scheduled: attributes.tm_scheduled,
                tm_posted: attributes.tm_posted,
                channel: channelData,
                product: productData,
                status: attributes.status,
                publishedAt: attributes.publishedAt,
                postUrl: attributes.post_url,
                type: attributes.type,
                media: attributes?.jsonData?.media 
                ? attributes?.jsonData?.media 
                : attributes.media?.data?.[0]?.attributes,               
                // media: attributes.media?.data?.map((item) => ({
                //     url: item.attributes.url,
                //     mime: item.attributes.mime,
                // })) || [],
            };
        }
        return null;
    };

    const renderData = normalizeData(postData, dataType);

    if (!renderData) {
        return null;
    }

    let socialMediaType;
    if (renderData.channel?.channel_type?.includes('facebook')) {
        socialMediaType = <FacebookIcon />;
    } else if (renderData.channel?.channel_type?.includes('instagram')) {
        socialMediaType = <InstagramIcon />;
    }

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true);
    };

    // const handleDeleteConfirm = () => {
    //     onDelete(renderData.id);
    //     setOpenDeleteDialog(false);
    // };
    const handleDeleteConfirm = async () => {
        try {
            await onDelete(renderData.id);
            dispatch(setGlobalMessage({ message: "Post deleted successfully!", severity: "success" }));
        } catch (error) {
            dispatch(setGlobalMessage({ message: "Failed to delete post. Please try again.", severity: "error" }));
        } finally {
            setOpenDeleteDialog(false);
        }
    };
    

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    };


//     const handleEdit = () => {
//         setEditedPost(postData); // Reset state with current post data
//         setOpenEditDialog(true); // Open modal
//     };

//     const handleSave = () => {
//         onEdit(editedPost); // Save updated post
//         setOpenEditDialog(false); // Close modal
//     };
    let isWithin24Hours;
    // console.log('renderData', renderData);
    if (renderData.status === 'published') {
        const times = renderData.publishedAt;
        isWithin24Hours = (times) => {
            return differenceInHours(new Date(), new Date(times)) < 24;
        };
    }
    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            pb: 1,
                        }}
                    >
                        <Typography align="center" gutterBottom variant="title">
                            {renderData.product?.name}
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> 
                        {/* <Can
                            I='manage'
                            this={subject('ManagePosts', { productId: parseNumber(renderData?.product?.id) })}
                            field='productId'
                        > */}
                            {(renderData.status === 'waiting' || renderData.status === 'draft' || renderData.status === 'errored') && (
                                <Tooltip title="Edit Post" sx={{ cursor: 'pointer' }}>
                                    <IconButton onClick={() => onEdit(postData)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {/* </Can> */}

                            {renderData.status === 'published' ? (
                                    (renderData.type === 'story-image' || renderData.type === 'story-video') ? (
                                        isWithin24Hours(renderData.tm_posted) ? (
                                            <Tooltip title="View Story">
                                                <IconButton onClick={() => window.open(renderData.postUrl, '_blank')}>
                                                    <HistoryEduIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Story Expired">
                                                <IconButton>
                                                    <UpdateDisabledIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    ) : (
                                        <Tooltip title="View Post" sx={{ cursor: 'pointer' }}>
                                            <IconButton onClick={() => window.open(renderData.postUrl, '_blank')}>
                                                <LinkIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                        ) : (
                                            <Tooltip title="Delete Post" sx={{ cursor: 'pointer' }}>
                                                <IconButton onClick={handleDeleteClick}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                    )}
                            {/* )} */}
                        {/* <Can
                            I='manage'
                            this={subject('ManagePosts', { productId: parseNumber(renderData?.product?.id) })}
                            field='productId'
                        > */}
                                {/* <Tooltip title="Delete Post" sx={{ cursor: 'pointer' }}>
                                    <IconButton onClick={handleDeleteClick}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip> */}
                            {/* </Can> */}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            pb: 3,
                        }}
                    >
                        {socialMediaType && (
                            <SvgIcon color="action" sx={{ mr: 1 }}>
                                {socialMediaType}
                            </SvgIcon>
                        )}
                        <Typography align="center" gutterBottom variant="body1">
                            {renderData.channel?.display_name}
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ pb: 3 }}>
                        <Box>
                            {renderData.type !== 'text' && (
                                <>
                                    {(renderData.type === 'reels' || renderData.type === 'single-video-post') && renderData.status === 'published' && renderData.media?.url && (
                                        <Box
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: 2,
                                                backgroundColor: '#f0f0f0',
                                            }}
                                        >
                                            <img
                                                src={renderData.media?.url}
                                                alt='media-image'
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {(renderData.type === 'single-image-post' || renderData.type === 'multi-images') && renderData.status === 'published' && renderData.media?.url && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1, // Add gap between images if multi-image post
                                            }}
                                        >
                                                <Box
                                                    sx={{
                                                        width: 100,
                                                        height: 100,
                                                        overflow: 'hidden',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 2,
                                                        backgroundColor: '#f0f0f0',
                                                    }}
                                                >
                                                    <img
                                                        alt='media-image'
                                                        src={renderData.media.url}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: 8,
                                                        }}
                                                    />
                                                </Box>
                                            
                                        </Box>
                                    )}
                                    {renderData.status !== 'published' &&
                                        (renderData.type === 'single-image-post' || renderData.type === 'multi-images') &&
                                        renderData.media && (
                                            <Box
                                                sx={{
                                                    width: 100,
                                                    height: 100,
                                                    overflow: 'hidden',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 2,
                                                    backgroundColor: '#f0f0f0',
                                                }}
                                            >
                                                <img
                                                    src={renderData.media.url}
                                                    alt={`Post Preview`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: 8,
                                                    }}
                                                />
                                            </Box>
                                    )}

                                    {(renderData.type === 'reels' || renderData.type === 'single-video-post') && renderData.status !== 'published' && renderData.media && (
                                        <Box
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: 2,
                                                backgroundColor: '#f0f0f0',
                                            }}
                                        >
                                            <video
                                                src={renderData.media?.url}
                                                controls
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                        <Typography align="center" variant="body1">
                            {renderData.content}
                        </Typography>
                    </Stack>
                </CardContent>
                <Box sx={{ flexGrow: 1 }} />
                <Divider />
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <SvgIcon color="action" fontSize="small">
                            <AccessTimeIcon />
                        </SvgIcon>
                        <Typography color="text.secondary" display="inline" variant="body2">
                            {renderData.status === 'published' && <Tooltip title={`${moment(renderData.publishedAt).format('lll')}`} sx={{ cursor: 'pointer' }}>{moment(renderData.publishedAt).utc().local().fromNow()}</Tooltip>}
                            {(renderData.status === 'waiting' || renderData.status === 'draft' || renderData.status === 'errored') && <Tooltip title={`${moment(renderData.tm_scheduled).format('lll')}`} sx={{ cursor: 'pointer' }}>{moment(renderData.tm_scheduled).utc().local().fromNow()}</Tooltip>}
                        </Typography>
                    </Stack>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <SvgIcon color="action" fontSize="small">
                            <QueryStatsIcon />
                        </SvgIcon>
                        <Chip size="small" label={renderData.status} style={{ color: '#FFFFFF', backgroundColor: getStatusBgColor(renderData.status.toLowerCase()) }} />
                    </Stack>
                </Stack>
            </Card>

            <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this post? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    {/* <Can
                        I='manage'
                        this={subject('ManagePosts', { productId: parseNumber(renderData?.product?.id) })}
                        field='productId'
                    > */}
                        <Button onClick={handleDeleteConfirm} color="error">
                            Delete
                        </Button>
                    {/* </Can> */}
                </DialogActions>
            </Dialog>
        </>
    );
};

PostCard.propTypes = {
    postData: PropTypes.object.isRequired,
    dataType: PropTypes.oneOf(['scheduledPosts', 'posts']).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
