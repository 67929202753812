
import { AUTH_TOKEN } from "./constant";
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const validateNumberField = (event) => {
  const allowedChars = /^[0-9\.\+\-\*\/]|[Backspace|Delete|ArrowUp|ArrowDown|ArrowLeft|ArrowRight]$|^$/;
  return allowedChars.test(event.key) && event.key !== 'e' && event.key !== 'E';
}

export const formatDateToUTC = (date) => {
  const utcDate = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
  return utcDate;
}

export const formatDateWithTimezone = (date) => {
  const timezone = momentTimeZone.tz.guess();
  const formattedDate = momentTimeZone(date).tz(timezone).format();
  return formattedDate;
}

export const transformArray = (arr, replaceChar) => {
  return arr.map(item => {
    let labelText = item;
    if (replaceChar) {
      labelText = item.replace(new RegExp(replaceChar, 'g'), ' ');
    }
    const label = labelText.replace(/\b\w/g, char => char.toUpperCase());
    return {
      label: label,
      value: item
    };
  });
}

export const transformText = (text, replaceChar='_') => {
    const labelText = text.replace(new RegExp(replaceChar, 'g'), ' ');
    return labelText.replace(/\b\w/g, char => char.toUpperCase());
}

export const removeNonValueFilters = (filterValues) =>{
  const filters = {};
  Object.keys(filterValues).forEach((key) => {
    if (filterValues[key] !== '') {
      Object.assign(filters, { [key]: filterValues[key] });
    }
  });
  return filters;
}

export const getStatusBgColor = (status ='') => {
  let backgroundColor = "#3174ad";
  switch (status.toLowerCase()) {
      case "published":
          backgroundColor = "#28a745"; // Green
          break;
      case "draft":
          backgroundColor = "#ffc107"; // Yellow
          break;
      case "error":
          backgroundColor = "#dc3545"; // Red
          break;
      case "waiting":
          backgroundColor = "#17a2b8"; // Blue
          break;
      default:
          backgroundColor = "#6c757d"; // Grey for any other status
          break;
  }
  return backgroundColor;
}

export const formatDate = (dateString) => new Date(dateString).toISOString().split("T")[0];

export const chartDefaultDateRange = () => {
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(`${currentYear}-01-01T00:00:00`).getTime();
  const endOfYear = new Date(`${currentYear}-12-31T23:59:59`).getTime();
  return { currentYear, startOfYear, endOfYear };
}
export const chartDefaultMonth = () => {
  const now = new Date();
  const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();
  const endOfCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59).getTime();
  return { startOfCurrentMonth, endOfCurrentMonth }
}

export const parseNumber = (str) => parseInt(str, 10);
